<template>
  <div ref="withdraw">
    <!-- top -->
    <van-sticky style="width: 100vw">
      <van-nav-bar
        :title="$returnBuySel('出货') + '纪录'"
        left-arrow
        @click-left="onClickLeft"
      >
        <template #right>
          <div class="rightSelect" @click="open = true">
            <van-icon name="filter-o" class="icon" />
            筛选
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <van-datetime-picker
      v-model="firstDate"
      type="date"
      title="选择年月日"
      v-if="showF"
      @cancel="cancelFTome(firstDate)"
      @confirm="setFTome"
      :min-date="minDate"
      :max-date="maxDate"
    />
    <van-datetime-picker
      v-model="endDate"
      type="date"
      title="选择年月日"
      v-if="showS"
      @cancel="cancelSTome(endDate)"
      @confirm="setSTome"
      :min-date="minDate"
      :max-date="maxDate"
    />
    <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
    <div class="wrap">
      <!-- :style="{
        backgroundImage:
          'url(' +
          $returnColor().path +
          ($store.state.selfData.member_id
            ? '/home-page_bg.png'
            : '/home-page_bg.png') +
          ')',
      }" -->
      <Drawer
        class="drawerWrap"
        v-if="open"
        @close="open = false"
        align="right"
        :closeable="true"
      >
        <div class="content">
          <p class="title">搜寻筛选</p>
          <div class="drawerContentWrap">
            <label for="">日期 </label>
            <div class="pickerWrap">
              <p @click="showF = true" class="datePick">
                {{ firstDate || "请选择日期" }}
              </p>
              ～
              <p @click="showS = true" class="datePick">
                {{ endDate || "请选择日期" }}
              </p>
            </div>
            <label for="">状态</label>
            <van-dropdown-menu style="border: 1px solid #cccccc">
              <van-dropdown-item
                v-model="type"
                :options="[
                  { text: '选择状态', value: false },
                  { text: '待付款', value: 0 },
                  { text: '成功', value: 1 },
                  { text: '逾时失效', value: 2 },
                  { text: '金额错误', value: 3 },
                  { text: '实名不符', value: 4 },
                  { text: '失败', value: 5 },
                ]"
              />
            </van-dropdown-menu>
            <div class="footBtnWrap">
              <van-button plain class="reset" @click="clearSelect"
                >重设</van-button
              >
              <van-button
                class="use"
                @click="
                  depositList = [];
                  saveData = false;
                  page = 1;
                  inFilter = true;
                  getDeposit();
                "
                >搜寻</van-button
              >
            </div>
          </div>
        </div>
      </Drawer>
      <div class="contentWrap">
        <!-- <v-md-date-range-picker class="timePick"></v-md-date-range-picker> -->
        <div class="dateWrap" v-if="showFast">
          <van-dropdown-menu class="dropdown dateDrop" style="width: 120px">
            <van-dropdown-item v-model="year" :options="dateOption" />
            <van-icon name="arrow-down" class="arrow" />
          </van-dropdown-menu>
          <van-dropdown-menu
            class="dropdown dateDrop"
            style="width: 95px; margin-left: 15px"
          >
            <van-dropdown-item v-model="month" :options="monthOption" />
            <van-icon name="arrow-down" class="arrow" />
          </van-dropdown-menu>
        </div>
        <van-cell-group
          inset
          :value="'¥' + deposit.amount"
          v-for="deposit in depositList"
          :key="deposit.order_id"
          style="margin: 0; margin-bottom: 8px"
          class="logWrap"
          @click="
            currentGropId == deposit.order_id
              ? (currentGropId = '')
              : (currentGropId = deposit.order_id)
          "
          :class="{ active: currentGropId == deposit.order_id }"
        >
          <div class="left">
            <p class="title">{{ deposit.status_ch }}</p>
            <p class="date">{{ deposit.created_at }}</p>
          </div>
          <div class="right">
            <p class="balance">¥ {{ deposit.amount | thousands }}</p>
            <van-icon name="arrow-down" />
          </div>

          <p
            class="orderId"
            @click.stop
            v-clipboard:copy="deposit.order_id"
            v-clipboard:success="onCopy"
          >
            <span class="title">订单编号: </span>
            <span class="id">
              {{ deposit.order_id }}
            </span>
            <i class="far fa-clone copyIcon"></i>
          </p>
        </van-cell-group>
      </div>
    </div>
  </div>
</template>
<script>
// import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Drawer from "vue-simple-drawer";
export default {
  components: { Drawer },
  data() {
    return {
      minDate: new Date(2021, 0, 1),
      maxDate: new Date(),
      type: false,
      open: false,
      currentGropId: "",
      year: new Date().getFullYear(),
      month:
        String(new Date().getMonth() + 1).length == 1
          ? "0" + String(new Date().getMonth() + 1)
          : String(new Date().getMonth() + 1),
      dateOption: [
        { text: 2021, value: 2021 },
        { text: 2022, value: 2022 },
        { text: 2023, value: 2023 },
        { text: 2024, value: 2024 },
      ],
      monthOption: [
        { text: "01", value: "01" },
        { text: "02", value: "02" },
        { text: "03", value: "03" },
        { text: "04", value: "04" },
        { text: "05", value: "05" },
        { text: "06", value: "06" },
        { text: "07", value: "07" },
        { text: "08", value: "08" },
        { text: "09", value: "09" },
        { text: "10", value: "10" },
        { text: "11", value: "11" },
        { text: "12", value: "12" },
      ],
      depositPaginate: {},
      depositList: [],
      depositPage: 1,
      withdrawList: [],
      withdrawPaginate: {},
      withdrawPage: 1,
      isLoading: false,
      value: "",
      showPicker: false,
      dates: { startDate: "" },
      firstDate: "",
      endDate: "",
      showF: false,
      showS: false,
      inFilter: false,
      saveData: false,
      showFast: true,
      next_page: true,
      current_page: 1,
    };
  },
  watch: {
    year() {
      this.current_page = 1;
      this.getDeposit();
    },
    month() {
      this.current_page = 1;
      this.getDeposit();
    },
    depositPage() {
      this.getDeposit();
    },
    open() {
      this.$store.dispatch(`setProhibitScroll`, this.open);
    },
  },
  methods: {
    clearSelect() {
      this.type = false;
      this.firstDate = "";
      this.endDate = "";
    },
    onCopy() {
      this.$toast.success("已复制");
    },
    setFTome(d) {
      this.firstDate = this.$returnTime(d);
      this.showF = false;
    },
    setSTome(d) {
      this.endDate = this.$returnTime(d);
      this.showS = false;
    },
    cancelSTome() {
      this.showS = false;
      return;
    },
    cancelFTome() {
      this.showF = false;
      return;
    },
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },
    onClickLeft() {
      this.$router.push(`/ordersC`);
    },
    onRefresh() {
      this.$store.dispatch("overlayHandler", true);
      this.getDeposit();
      this.getWithdraw();
    },
    getDeposit() {
      let params = {
        page: this.current_page,
        start: this.firstDate,
        end: this.endDate,
        status: this.type,
      };
      Object.keys(params).forEach((e) => {
        if ((!params[e] && params[e] != 0) || String(params[e]).trim() == "") {
          delete params[e];
        }
      });
      if (this.inFilter && (params.start || params.end)) {
        this.showFast = false;
      } else {
        this.showFast = true;
        this.inFilter = false;
      }
      if (!params.start && !params.end) {
        params.start = `${this.year}-${this.month}-01`;
        params.end = `${this.year}-${this.month}-${new Date(
          this.year,
          this.month,
          0
        ).getDate()}`;
      }
      if (params.start) {
        params.start += " 00:00:00";
      }
      if (params.end) {
        params.end += " 23:59:59";
      }
      if (String(params.status) == "false") delete params.status;
      this.$http.get(`/mobile/orders`, { params }).then((res) => {
        if (res.data.code == 0) {
          this.saveData = JSON.parse(JSON.stringify(params));
          let listData = JSON.parse(JSON.stringify(this.depositList));
          if (params.page == 1) {
            listData = [];
          }
          res.data.data.forEach((data) => {
            let have = false;
            this.depositList.forEach((e) => {
              if (e.order_id == data.order_id) {
                have = true;
              }
            });
            if (!have) {
              listData.push(data);
            }
          });
          this.depositList = listData;
          this.depositPaginate = res.data.paginate;
          this.isLoading = false;
          this.$store.dispatch("overlayHandler", false);
          this.current_page = res.data.current_page;
          this.next_page = res.data.next_page;
          this.open = false;
        }
      });
    },
    scrollPage() {
      if (window.scrollY + window.screen.height >= document.body.scrollHeight) {
        if (this.next_page) {
          this.current_page += 1;
          this.getDeposit();
        }
      }
    },
  },
  created() {
    this.getDeposit();
  },
  mounted() {
    window.addEventListener("scroll", this.scrollPage);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollPage);
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #f1f5fe;
}
.contentWrap {
  width: 90%;
}
.dropdown {
  margin-bottom: 15px;
  position: relative;
  .arrow {
    position: absolute;
    right: 10px;
    top: 35%;
  }
  .van-dropdown-menu__title {
    margin-right: auto !important;
  }
  .van-dropdown-menu__bar {
    border-radius: 10px !important;
  }
}

.contentWrap {
  // background-attachment: fixed;
}
.dateWrap {
  display: flex;
  margin-bottom: 10px;
}
.logWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  height: 60px;
  flex-wrap: wrap;
  transition: 0.3s ease-in-out;
  .orderId {
    height: 0;
    overflow: hidden;
  }
  .left {
    width: 60%;
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
    .date {
      font-size: 12px;
      color: #999999;
    }
  }
  .right {
    width: 40%;
    display: flex;
    // height: 100%;
    align-items: center;
    justify-content: flex-end;
    .balance {
      color: #4361ff;
      font-size: 14px;
      font-weight: 500;
      margin-right: 5px;
    }
    i {
      color: #999999;
      font-weight: bold;
      font-size: 16px;
      transition: 0.3s ease-in-out;
    }
  }
}
.logWrap.active {
  height: 100px;
  .orderId {
    height: auto;
    width: 100%;
    font-size: 12px;
    padding-right: 20px;
    display: flex;
    position: relative;
    .copyIcon {
      position: absolute;
      right: 0px;
      font-size: 14px;
    }
    .title {
      color: #999999;
      white-space: nowrap;
    }
    .id {
      margin-left: 5px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .right {
    i {
      transform: rotate(180deg);
    }
  }
}
.rightSelect {
  display: flex;
  align-items: center;
  // color: #009aaa;
  .icon {
    // color: #009aaa !important;
  }
}
.leftSelect {
  position: fixed;
  top: 46px;
  bottom: 0px;
  right: 0px;
  left: 0;
  background-color: rgba(black, 0.8);
  z-index: 999;
  overflow: scroll;
  .selectContent {
    width: 80%;
    background-color: #fff;
    position: fixed;
    right: 0;
    top: 46px;
    bottom: 0;
  }
}
.drawerTime {
  border: 0.5px solid #cccccc;
  color: #cccccc;
}
.drawerWrap {
  color: #333333 !important;
  .content {
    .title {
      font-size: 16px;
      font-weight: 500;
      background-color: rgb(242, 242, 242);
      line-height: 50px;
      padding-left: 10px;
    }
  }
  .drawerContentWrap {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    label {
      color: #333333;
      margin-top: 5px;
      line-height: 30px;
    }
    .drawerTime {
      width: 115px;
      // height: 24px;
    }
    .pickerWrap {
      display: flex;
      align-items: center;
      .datePick {
        width: 115px;
        height: 30px;
        align-items: center;
        line-height: 30px;
        border: 0.5px solid #cccccc;
        text-align: center;
        color: #cccccc;
      }
    }
  }
  .footBtnWrap {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    button {
      width: 80px;
      height: 24px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .reset {
      border: 0.5px solid #009aaa;
      color: #009aaa;
    }
    .use {
      background-color: #009aaa;
      color: white;
    }
  }
}
</style>
